.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.form {
    width: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    overflow-y: auto;
    height: 260px;
    z-index: 9999999999999;
}

.loading-message {
    font-family: sans-serif;
    letter-spacing: .5px;
    font-size: 1.15rem;
}

.button {
    font-size: 1rem;
    letter-spacing: .5px;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    margin: 7px;
    background-color: #ffc627;
    color: black;
    transition: 0.4s;
}
.button:hover {
    background-color: black;
    color: #ffc627;
}
