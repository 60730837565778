.input {
    width: 300px;
    font-size: 1.15rem;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid gray;
}

.input:focus {
    outline: none;
    border-bottom: 1px solid #ffc627;
}

.process-button {
    font-size: 1rem;
    letter-spacing: .5px;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    margin: 7px;
    background-color: #ffc627;
    color: black;
    transition: 0.4s;
}
.process-button:hover {
    background-color: black;
    color: #ffc627;
}

.contact-header {
    margin-top: 0;
    font-size: 1.25rem;
    letter-spacing: .5px;
    font-family: sans-serif;
}
.oneTime span {
    color: red;
    font-weight: bold;
    letter-spacing: .1em;
}
 .strikeout{
    text-decoration: line-through;
}

.radio-btn {
    margin-right: 8px;
}

label {
    font-family: sans-serif;
}

.total-form {
    text-align: center;
}

.payment-container, .contact-container {
    margin-bottom: 20px;
}
.billing-container {
    margin-bottom: 20px;
}

.react-phone-number-input {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
