.root {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.test {
    border: 1px solid #B5B5B5;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    margin: 20px 0 20px 0;
    box-sizing: border-box;
}

.orderBtn {
    font-size: 1rem;
    letter-spacing: .5px;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    display: block;
    margin: 15px auto 15px auto;
    background-color: #ffc627;
    color: black;
    transition-duration: 0.4s;
}
.orderBtn:hover {
    background-color: black;
    color: #ffc627;
    cursor: pointer;
}

.payment-header {
    text-align: center;
}

.StripeElement {
    border: 2px solid black;
    padding: 15px;
    border-radius: 7px;
    z-index: 0;
}

.nested-stripe-container {
    margin: 0 15px 0 15px;
    z-index: 0;
}



