.selectHeader {
  font-family: sans-serif;
  line-height: 30px;
  max-width: 900px;
}

.selectPhoto {
  width: 50vw;
}

@media (max-width: 585px) {
  .selectHeader {
    font-size: 1rem;
    line-height: 25px;
    padding: 0 15px 0 15px;
    margin-top: 0;
  }

  .selectPhoto {
    width: 75vw;
  }
}

