body {
    margin: 0;
}

/*.app {*/
/*    overflow: hidden;*/
/*}*/

.childOne {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


/*.childTwo {*/
/*    max-width: 80%;*/
/*}*/

.childThree {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*max-width: 80%;*/
}

@media (max-width: 720px) {
    .childOne {
        display: flex;
        flex-direction: column;
    }
}
