.headerText {
  font-family: sans-serif;
  color: #ffc627;
  padding: 0 5px 35px 5px;
  margin-top: 0;
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 585px) {
  .headerText {
    font-size: 1.5rem;
    max-width: 80vw;
  }
}
