.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding-top: 30px;
}

.thankyouSubRoot {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;

}

.thank-you-header {
    font-family: sans-serif;
    letter-spacing: .5px;
    font-size: 2.75rem;
    margin-bottom: 0;
}

.thank-you-subHeader {
    font-family: sans-serif;
    letter-spacing: .5px;
    font-size: 1.3rem;
    margin-bottom: 3%;
}
.shopJbLink{
    color: #be8737;
}
